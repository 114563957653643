<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.orders") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end"
      >
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Заказы"
          name="Заказы.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete"
            >Excel</el-button
          >
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'doctorCabinet'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.day"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.service"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.patient_id.show">
            <el-input
              size="mini"
              v-model="filterForm.patient_id"
              :placeholder="columns.patient_id.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.day.show">
            <el-date-picker
              v-model="filterForm.day"
              :placeholder="columns.day.title"
              size="mini"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
            ></el-date-picker>
          </th>
          <th v-if="columns.service.show">
            <!-- <select-service-name-inventory
              v-model="filterForm.service_id"
              :placeholder="columns.service.title"
              size="mini"
            >
            </select-service-name-inventory> -->
            <el-input
              size="mini"
              v-model="filterForm.service_name"
              :placeholder="columns.service.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="patient in list"
          :key="patient.id"
          v-on:dblclick="show(patient.id)"
          style="cursor: pointer"
        >
          <td v-if="columns.id.show">{{ patient.id }}</td>
          <td v-if="columns.patient_id.show">
            {{
              patient.patient && patient.patient.surname
                ? patient.patient.surname
                : ""
            }}
            {{ patient.patient ? patient.patient.first_name : "" }}
          </td>
          <td v-if="columns.day.show">{{ patient.day }}</td>
          <td v-if="columns.service.show">
            <p
              v-for="(item, index) in patient.services"
              :key="index"
              class="p-0 m-0"
            >
              {{ item ? item.name : "" }}
            </p>
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <div class="za_btn text-center">
              <el-button
                round
                @click="show(patient)"
                size="mini"
                type="primary"
                icon="el-icon-view"
                >{{ $t("message.show_0") }}</el-button
              >
              <!-- v-if="patient.patient_conculations_count" -->
              <el-button
                round
                @click="showConclusion(patient)"
                size="mini"
                type="success"
                icon="el-icon-view"
                >{{ $t("message.show_conclusions") }}</el-button
              >
              <el-button
                @click="createModal(patient.id)"
                type="primary"
                icon="el-icon-edit"
                >{{ $t("message.receive_") }}</el-button
              >
            </div>
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      :visible.sync="drawerCreateCoculation"
      size="100%"
      :drawer="drawerCreateCoculation"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
    >
      <div>
        <crm-create
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
          ref="createDrawer"
        ></crm-create>
      </div>
    </el-drawer>

    <el-drawer
      :visible.sync="drawerUpdateCoculation"
      size="85%"
      :drawer="drawerUpdateCoculation"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
      class="mytab_IdP"
    >
      <div>
        <crm-update
          :reloadModel="reopenUpdate"
          :patient="selected_patient"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
        ></crm-update>
      </div>
    </el-drawer>

    <el-drawer
      title="Изменить тег"
      size="70%"
      :visible.sync="drawer.show.status"
      :ref="drawer.show.name"
      @opened="drawerOpened(drawer.show.component)"
      @closed="drawerClosed(drawer.show.component)"
    >
      <div>
        <crm-show
          :selected_id="selected_id"
          :reloadModel="reopenShow"
          :ref="drawer.show.component"
          :drawer-name="drawer.show.name"
        ></crm-show>
      </div>
    </el-drawer>
    <el-drawer
      title="Изменить тег"
      size="70%"
      :visible.sync="drawer.conclusionShow.status"
      :ref="drawer.conclusionShow.name"
      @opened="drawerOpened(drawer.conclusionShow.component)"
      @closed="drawerClosed(drawer.conclusionShow.component)"
    >
      <div>
        <crm-all-show
          :selected_patient="selected_patient"
          :reloadModel="reopenShow"
          :ref="drawer.conclusionShow.component"
          :drawer-name="drawer.conclusionShow.name"
        ></crm-all-show>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/createConclusion";
import CrmShow from "./components/Parts/crm-lab-conculations-show";
import CrmAllShow from "./components/Parts/crm-all-conculations-show";
import CrmUpdate from "./components/addOrder";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import Inventory from "../../components/inventory-select/select-service_name-inventory";
export default {
  mixins: [list, drawer],
  name: "PatientServiceList",
  components: {
    CrmCreate,
    CrmUpdate,
    CrmShow,
    CrmAllShow,
    "select-service-name-inventory": Inventory,
  },
  data() {
    return {
      backUrl: process.env.VUE_APP_URL_DOCS,
      drawerCreateCoculation: false,
      drawerUpdateCoculation: false,
      filterForm: {},
      reopenUpdate: false,
      excel_data: [],
      reopenShow: false,
      drawerShow: false,
      showRef: "drawerRef",
      excel_fields: {},
      selected_id: null,
      loadingData: false,
      selected_patient: [],
      drawer: {
        show: {
          name: "show",
          status: false,
          component: "componentDrawerShow",
        },
        conclusionShow: {
          name: "conclusionShow",
          status: false,
          component: "componentDrawerConclusionShow",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "doctorCabinet/informal_patient_list",
      columns: "doctorCabinet/columns",
      pagination: "doctorCabinet/pagination",
      filter: "doctorCabinet/filter",
      sort: "doctorCabinet/sort",
      conditions: "conditions/list",
      token: "auth/token",
    }),
  },
  async created() {},
  async mounted() {
    await this.controlExcelData();
    if (this.conditions && this.conditions.length === 0)
      await this.loadConditions({ doctor: true });
  },
  methods: {
    ...mapActions({
      updateList: "doctorCabinet/informalPatientList",
      updateDoctor: "doctorCabinet/updateDoctor",
      showModel: "laboratoryConclusions/showOrder",
      updateSort: "doctorCabinet/updateSort",
      updateFilter: "doctorCabinet/updateFilter",
      updateColumn: "doctorCabinet/updateColumn",
      updatePagination: "doctorCabinet/updatePagination",
      empty: "doctorCabinet/empty",
      refreshData: "doctorCabinet/refreshData",
      loadConditions: "conditions/iventory",
    }),
    async fetchData() {
      const query = {
        doctor: "patsents",
        ...this.filterForm,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData();
    },
    async show(model) {
      // this.$loadingCursor("wait");
      this.drawer.show.status = true;
      this.selected_id = model.id;
      // await this.showModel(model.id)
      //   .then((res) => {
      //     this.$loadingCursor("default");
      //   })
      //   .catch((err) => {
      //     // this.$alert();
      //     this.$loadingCursor("default");
      //   });
    },
    showConclusion(model) {
      // this.$loadingCursor("wait");
      this.drawer.conclusionShow.status = true;
      this.selected_patient = model.patient;
    },
    async createModal(id) {
      this.$confirm("Вы действительно хотите это сделать?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмен",
        type: "warning",
      })
        .then(() => {
          this.$loadingCursor("wait");
          this.updateDoctor(id)
            .then((res) => {
              this.$loadingCursor("default");
              this.refresh();
            })
            .catch((err) => {
              this.$loadingCursor("default");
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена",
          });
        });
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    emptyModel() {
      this.$refs["createDrawer"].afterClosed();
      this.empty();
    },
  },
};
</script>
<style lang="scss" scoped>
.router_style {
  td {
    padding: 0 !important;
    margin: 0px !important;
    a {
      display: block;
      padding: 0.3rem;
      color: #000;
      font-weight: 200 !important;
      font-size: 12px !important;
    }
  }
}
</style>



